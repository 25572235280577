@use "../../scss/" as *;
@import "../../scss/_variables";
@import "../../scss/_mixin";
@import "../../scss/_breakpoint";

button {
    cursor: pointer;
    font-family: $font-family;
}

.btn {
    border: 4px solid transparent;
    background-color: $white;
    color: $black;
    border-radius: $border-radius;
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    // box-shadow: 0px 0px 7px 8px #4b53c44d;
    transition: box-shadow 0.3s ease;
    position: relative;
}

.btn2 {
    border: 4px solid transparent;
    background-color: $main-color;
    color: $white;
    border-radius: $border-radius;
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: 0px 0px 7px 8px #ff00004d;
    transition: box-shadow 0.3s ease;
    position: relative;
}

.btn:hover {
    // box-shadow: 0px 0px 7px 8px #7880ee4d;
    color: $green;
}

.btn2:hover {
    box-shadow: 0px 0px 7px 15px #ff00004d;
}

.btn-outline {
    border: 3px solid $white;
    background-color: transparent;
    color: $white;
    box-shadow: unset;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.btn2-outline {
    border: 3px solid $white;
    background-color: transparent;
    color: $white;
    box-shadow: unset;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.btn2-outline:hover {
    box-shadow: unset;
    color: $main-color;
    background-color: $white;
}

.btn-outline:hover {
    box-shadow: unset;
    color: $white;
    background-color: $green;
}

.btn.small {
    border-width: 2px;
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
}
