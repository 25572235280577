.widgets {
  flex: 0.3;
}

.widgets__input {
  display: flex;
  align-items: center;
  border: none;
  width: 90%;
  background-color: #e6ecf0;
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  /* margin-left: 20px; */
}

.widgets__input > input {
  border: none !important;
  background-color: #e6ecf0 !important;
  /* color: red; */
}

.widgets__input > input:focus {
  outline: none;
}

.widgets__searchIcon {
  font-size: 18px !important;
  margin-right: 3px;
  color: #50b7f5 !important;
}

.widgets__widgetContainer {
  margin-top: 15px;
  /* margin-left: 20px; */
  padding: 15px;
  background-color: #f5f8fa;
  /* border-radius: 20px; */
}

.widgets__widgetContainer > h2 {
  font-size: 18px;
  font-weight: 800;
}

.rightbarFriendList {
  padding: 0;
  margin: 0;
  list-style: none;
}
.rightbarFriendListItem {
  padding-bottom: 5px;
}
