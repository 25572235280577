.rightbar {
    flex: 3.5;
  }
  
  .rightbarWrapper {
    padding: 20px 20px 0 0;
  }
  
  .birthdayContainer {
    display: flex;
    align-items: center;
  }
  
  .birthdayImg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .birthdayText {
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 10px;
  }
  
  .rightbarAd {
    width: 100%;
    border-radius: 10px;
    margin: 30px 0;
  }
  