.share {
    width: 100%;
    height: 120px !important;
    border-radius: 10px; 
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68) !important;
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68) !important;
  }

  .card {
    border-radius: 10px;
    margin: 50px 0 !important;
    margin-bottom: 30px !important;
  }
  
  .shareWrapper {
    padding: 10px;
    /* width:100%; */
  }
  
  .shareTop {
    display: flex;
    align-items: center;
      /* max-height: 50px; */
  }
  
  .shareProfileImg {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .shareInput {
    border: none;
    width: 100% !important;
  
  }
  
  .shareInput:focus {
    outline: none;
  }
  
  /* .shareHr {
    margin: 10px;
  }
   */
  .shareBottom {
    display: flex;
    margin-top: 0px !important;
    margin-left: 20px !important;
    align-items: center;
    justify-content: space-between;
  }
  
  .shareOptions{
      display: flex;
      margin-left: 40px !important;
      margin-top: -30px !important;
  }
  
  .shareOption{
      display: flex;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
  }
  
  .shareIcon{
      font-size: 18px !important;
      margin-right: 3px;
      color: #50b7f5 !important;
  }
  
  .shareOptionText{
      font-size: 15px !important;
      font-weight: 200 !important;
  }
  
  .shareButton{
      border: none;
      padding: 7px;
      border-radius: 5px;
      background-color: #50b7f5;
      font-weight: 500;
      margin-right: 2px !important;
      cursor: pointer;
      color: white;
  }