@use "../../scss/" as *  ;
@import "../../scss/_variables"  ;
@import "../../scss/_mixin"  ;
@import "../../scss/_breakpoint"  ;

.hero-slide {
    margin-bottom: 3rem  ;
}

.hero-slide__item {
    padding: 9rem 0  ;
    width: 100%  ;
    position: relative  ;
    background-position: center  ;
    background-size: cover  ;
    background-repeat: no-repeat  ;

    &::before {
        content: ""  ;
        position: absolute  ;
        top: 0  ;
        left: 0  ;
        width: 100%  ;
        height: 100%  ;
        @include overlay()  ;
    }

    &::after {
        content: ""  ;
        position: absolute  ;
        bottom: 0  ;
        left: 0  ;
        width: 100%  ;
        height: 100px  ;
        // background-image: linear-gradient(to top, #0f0f0f, rgba(#000, 0))  ;
        background-image: linear-gradient(to top, $body-bg, rgba($black, 0))  ;
    }

    &__content {
        @include flex(center, center) ;
        position: relative  ;

        &__info {
            width: 55%  ;
            padding: 0 3rem  ;
            position: relative  ;

            @include tablet {
                width: 100%  ;
            }

            & > * ~ * {
                margin-top: 3rem  ;
            }

            .title2 {
                font-size: 50px  ;
                font-weight: 700  ;
                line-height: 1  ;

                @include tablet {
                    font-size: 30px  ;
                }
            }

            .overview {
                font-weight: 700  ;
            }

            .btns > * ~ * {
                margin-left: 1rem  ;
            }

            .btns,
            .title,
            .overview {
                opacity: 0  ;
                transform: translateY(-100px)  ;
                transition: transform 0.5s ease, opacity 0.5s ease  ;
            }
        }

        &__poster {
            flex: 1  ;
            @include flex(center, flex-start);
            position: relative  ;

            img {
                width: 400px  ;
                border-radius: $border-radius  ;
                box-shadow: $box-shadow  ;

                transform: scale(0)  ;
                transition: transform 0.7s ease  ;
            }

            @include tablet {
                display: none  ;
            }
        }
    }

    &.active > &__content > &__content__poster {
        img {
            transform: scale(1)  ;
        }
    }

    &.active > &__content > &__content__info {
        .btns,
        .title,
        .overview {
            opacity: 1  ;
            transform: translateY(0)  ;
        }

        .title {
            transition-delay: 0.3s, 0.3s  ;
        }

        .overview {
            transition-delay: 0.6s, 0.6s  ;
        }

        .btns {
            transition-delay: 0.9s, 0.9s  ;
        }
    }
}
