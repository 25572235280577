.storyImg {
    /* width: 40px;
    height: 40px;
    border-radius: 50%; */
    object-fit: cover;
    /* background-size: cover; */
     /* background-repeat: no-repeat; */
     background-size: 100% 100%;
}

/* .coverImg { */
    /* width: 1200px;
    height: 250px; */
    /* border-radius: 50%;  */
    /* object-fit: contain; */
    /* background-size: cover; */
     /* background-repeat: no-repeat; */
     /* background-size: 100% 100%; */
  /* } */