@use "../../scss/variables" as *;
@import "../../scss/_variables";
@import "../../scss/_mixin";
@import "../../scss/_breakpoint";
input {
    border: 0;
    background-color: $black;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: $border-radius;
    color: $txt-color;
    font-family: $font-family;
}
