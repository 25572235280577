.media {
    display: flex !important ;
    flex-direction: column !important !important ;
    width: 200px !important ;
    padding: 5px !important ;
    margin: 5px 0 !important ;
    background-color: #282c34 !important ;
    border-radius: 10px !important ;
    position: relative !important ;
    font-family: "Montserrat", sans-serif !important ;
  }

  .trending {
    display: flex !important ;
    flex-wrap: wrap !important ;
    justify-content: space-around !important ;
  }
  
  .media:hover {
    background-color: white !important ;
    color: black !important ;
  }
  
  @media (max-width: 550px) {
    .media {
      width: 46% !important ;
    }
  }
  
  .poster {
    border-radius: 10px !important ;
  }
  
  .title {
    width: 100% !important ;
    text-align: center !important ;
    font-size: 17px !important ;
    padding: 8px 0 !important ;
  }
  
  .subTitle {
    display: flex !important ;
    justify-content: space-between !important ;
    padding-bottom: 3px !important ;
    padding: 0 2px !important ;
    padding-bottom: 3px !important ;
  }

  * {
    scroll-behavior: smooth !important;
  }
  
  .app {
    min-height: 100vh !important ;
    background-color: #39445a !important ;
    color: white !important ;
    padding-top: 130px !important ;
    padding-bottom: 70px !important ;
  }
  
  @media (max-width: 700px) {
    .app {
      padding-top: 70px !important ;
    }
  }
  
  .pageTitle {
    text-transform: uppercase !important ;
    display: flex !important ;
    justify-content: center !important ;
    font-family: "Montserrat", sans-serif !important ;
    font-size: 2vw !important ;
    padding: 4px !important ;
    border-radius: 50px !important ;
    color: white !important ;
  }
  
  @media (max-width: 1000px) {
    .pageTitle {
      font-size: 6.4vw !important ;
    }
  }